<template>
  <section class="page-content">
    <p class="description">
      W zakładce <strong>stoisko wystawcy</strong> uzupełnij informacje o
      stoisku oraz dodaj logotypy firmy niezbędne do przygotowania wizualizacji
      stoiska.
    </p>
    <download-data page-type="_eb_" v-if="!disabled" />
    <h2>Informacje o stoisku:</h2>
    <form class="main-form">
      <v-radio-button
        id="first-booth-option"
        name="_eb_booth_type"
        label="Korzystam ze stoiska Pracuj.pl przydzielonego do mojego pakietu."
        :disabled="disabled"
        @auto-save="sendData(false)"
        v-model="formData._eb_booth_type"
      />
      <v-radio-button
        id="second-booth-option"
        name="_eb_booth_type"
        label="Korzystam z pakietu bez stoiska Pracuj.pl, posiadam swoje stoisko."
        :disabled="disabled"
        @auto-save="sendData(false)"
        v-model="formData._eb_booth_type"
      />
      <div v-show="formData._eb_booth_type === 'first-booth-option'">
        <div class="space-content">
          <h2>Logotyp na stoisku:</h2>
          <v-radio-button
            id="logo-everywhere"
            name="_eb_logo_option"
            label="Chcę obrandować stoisko jednym logotypem"
            :disabled="disabled"
            @auto-save="sendData(false)"
            v-model="formData._eb_logo_option"
          />
        </div>
        <div v-show="formData._eb_logo_option === 'logo-everywhere'">
          <h2>Dodaj logotyp firmy do obrandowania stoiska:</h2>
          <p class="informations">
            Prosimy o załadowanie logo o wysokiej jakości z możliwością
            skalowania. Logo powinno być załadowane w formacie: pdf, eps lub ai.
            Maksymalna waga pliku to 5 MB.
          </p>
          <p class="informations">
            <strong
              >Jeśli masz problem z wgraniem logo w poprawnym formacie (pdf, eps
              lub ai) skontaktuj się z nami mailowo
              <a
                class="important-info"
                :href="`mailto:${companyGuardian.email}`"
                >{{ companyGuardian.email }}</a
              ></strong
            >
          </p>
          <v-upload
            id="_ed_logo"
            name="_ed_logo"
            label="Załaduj logo firmy"
            label-alt="Zmień logo firmy"
            :confirmMessage="'Potwierdź dodanie logo'"
            file-type="vector"
            :edition="activeEdition"
            :company="companyData.id"
            :disabled="disabled"
            v-model="formData._ed_logo"
            :detailId="getDetailId('_ed_logo')"
          />
        </div>
        <v-radio-button
          id="logo-selected-places"
          name="_eb_logo_option"
          label="Chcę obrandować stoisko różnymi logotypami"
          :disabled="disabled"
          @auto-save="sendData(false)"
          v-model="formData._eb_logo_option"
        />
      </div>
      <div
        v-show="
          formData._eb_logo_option === 'logo-selected-places' &&
          formData._eb_booth_type === 'first-booth-option'
        "
      >
        <h2>Dodaj logotyp firmy do obrandowania stoiska:</h2>
        <p class="informations">
          Prosimy o załadowanie logo o wysokiej jakości z możliwością
          skalowania. Logo powinno być załadowane w formacie: pdf, eps lub ai.
          Maksymalna waga pliku to 5 MB.
        </p>
        <p class="informations">
          <strong>
            Jeśli masz problem z wgraniem logo w poprawnym formacie (pdf, eps
            lub ai) skontaktuj się z nami mailowo
            <a class="important-info" :href="`mailto:${companyGuardian.email}`">
              {{ companyGuardian.email }}
            </a>
          </strong>
        </p>
        <div>
          <h3>{{ leftSideLogo }}</h3>
          <v-upload
            id="_eb_visualisation_logo_2"
            name="_eb_visualisation_logo_2"
            label="Załaduj logo"
            label-alt="Zmień logo"
            :confirmMessage="'Potwierdź dodanie logo'"
            file-type="vector"
            :edition="activeEdition"
            :company="companyData.id"
            :disabled="disabled"
            v-model="formData._eb_visualisation_logo_2"
            :detailId="getDetailId('_eb_visualisation_logo_2')"
          />
        </div>
        <div>
          <h3>{{ middleLogo }}</h3>
          <v-upload
            id="_eb_visualisation_logo_3"
            name="_eb_visualisation_logo_3"
            label="Załaduj logo"
            label-alt="Zmień logo"
            :confirmMessage="'Potwierdź dodanie logo'"
            file-type="vector"
            :edition="activeEdition"
            :company="companyData.id"
            :disabled="disabled"
            v-model="formData._eb_visualisation_logo_3"
            :detailId="getDetailId('_eb_visualisation_logo_3')"
          />
        </div>
        <div v-if="showLogoField(['Elite', 'Enterprise', 'Pro'])">
          <h3>Dodaj logo po prawej stronie ścianki</h3>
          <v-upload
            id="_eb_visualisation_logo_4"
            name="_eb_visualisation_logo_4"
            label="Załaduj logo"
            label-alt="Zmień logo"
            :confirmMessage="'Potwierdź dodanie logo'"
            file-type="vector"
            :edition="activeEdition"
            :company="companyData.id"
            :disabled="disabled"
            v-model="formData._eb_visualisation_logo_4"
            :detailId="getDetailId('_eb_visualisation_logo_4')"
          />
        </div>
        <div v-if="showLogoField(['Elite', 'Enterprise'])">
          <h3>Dodaj logo na ladzie wystawowej</h3>
          <v-upload
            id="_eb_visualisation_logo_5"
            name="_eb_visualisation_logo_5"
            label="Załaduj logo"
            label-alt="Zmień logo"
            :confirmMessage="'Potwierdź dodanie logo'"
            file-type="vector"
            :edition="activeEdition"
            :company="companyData.id"
            :disabled="disabled"
            v-model="formData._eb_visualisation_logo_5"
            :detailId="getDetailId('_eb_visualisation_logo_5')"
          />
        </div>
      </div>
      <visualisation
        v-show="formData._eb_booth_type === 'first-booth-option'"
      />
      <div v-if="!disabled" class="send-btn-cont">
        <main-btn
          content="Zapisz wersję roboczą"
          tooltip="Wersja robocza umożliwia Ci naniesienie zmian i edytowanie tej zakładki. Pamiętaj aby jak najszybciej zapisać wersję ostateczną materiałów, która zostanie przesłana do organizatora wydarzenia."
          :submit="true"
          :saving="loading"
          :gray="true"
          @click="sendData(false)"
        />
        <main-btn
          content="Zapisz wersję ostateczną"
          tooltip="Wersja ostateczna to finalna informacja jaka zostanie przesłana do Pracuj.pl na potrzeby organizacji wydarzenia"
          :submit="true"
          :saving="loading"
          @click="
            sendData(true, '_eb_disabled', ignoredKeys[formData._eb_booth_type])
          "
        />
      </div>
      <div v-else>
        <p>
          <strong
            >Ustawienia zakładki zostały przekazane do Pracuj.pl. Jeśli chcesz
            dokonać zmian skontaktuj się pod adresem
            <a
              class="important-info"
              :href="`mailto:${companyGuardian.email}`"
              >{{ companyGuardian.email }}</a
            ></strong
          >
        </p>
      </div>
      <p class="text-center margin-tp-1" v-show="message">
        <strong :class="`message--${messageType}`">{{ message }}</strong>
      </p>
    </form>
  </section>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import sendDataAndDispatch from "@/mixins/sendDataAndDispatch.js";
import assignDataFromDatabase from "@/mixins/assignDataFromDatabase.js";
import getDetailId from "@/mixins/getDetailId.js";

export default Vue.extend({
  mixins: [sendDataAndDispatch, assignDataFromDatabase, getDetailId],
  data() {
    return {
      loading: false,
      message: "",
      messageType: "ok",
      main_logo: "",
      leftSideLogo: "Dodaj logo po lewej stronie ścianki",
      middleLogo: "Dodaj logo na środku ścianki",
      disabled: false,
      formData: {
        _eb_booth_type: "",
        _eb_logo_option: "",
        _ed_logo: "",
        _eb_disabled: false,
      },
      ignoredKeys: {
        "first-booth-option": [
          "_eb_visualisation_logo_1",
          "_eb_visualisation_logo_2",
          "_eb_visualisation_logo_3",
          "_eb_visualisation_logo_4",
          "_eb_visualisation_logo_5",
        ],
        "second-booth-option": [
          "_eb_logo_option",
          "_eb_visualisation_logo_1",
          "_eb_visualisation_logo_2",
          "_eb_visualisation_logo_3",
          "_eb_visualisation_logo_4",
          "_eb_visualisation_logo_5",
          "_ed_logo",
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "companyData",
      "activeEditionOpened",
      "visualisationUrl",
      "currentCompanyPackage",
      "userRole",
      "companyGuardian",
    ]),
    visualisationAvailable() {
      // tslint:disable-next-line
      if (
        this.formData._ed_logo ||
        (this.formData._eb_visualisation_logo_2 &&
          this.formData._eb_visualisation_logo_3 &&
          this.formData._eb_visualisation_logo_4 &&
          this.formData._eb_visualisation_logo_5)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    showLogoField(allowedPackages) {
      return allowedPackages.includes(this.currentCompanyPackage.name);
    },
    checkIfPageIsDisabled() {
      this.disabled = this.formData._eb_disabled;

      if (!this.activeEditionOpened) {
        this.disabled = true;
      }
    },
    prepareData() {
      this.assingData("_eb_booth_type", "");
      this.assingData("_eb_logo_option", "");
      // this.assingData('_eb_visualisation_logo_1', '');
      this.assingData("_eb_visualisation_logo_2", "");
      this.assingData("_eb_visualisation_logo_3", "");
      this.assingData("_ed_logo", "");
      this.assingData("_eb_disabled", false, "boolean");

      if (this.showLogoField(["Elite", "Enterprise", "Pro"])) {
        this.assingData("_eb_visualisation_logo_4", "");
      }
      if (this.showLogoField(["Elite", "Enterprise"])) {
        this.assingData("_eb_visualisation_logo_5", "");
      }

      if (this.currentCompanyPackage.name === "Classic") {
        this.leftSideLogo = "Dodaj logo na ściance";
        this.middleLogo = "Dodaj logo na ladzie";
      }

      if (this.currentCompanyPackage.name === "Pro") {
        this.middleLogo = "Dodaj logo na ladzie";
      }
    },
  },
  created() {
    this.prepareData();
    this.checkIfPageIsDisabled();
  },
  watch: {
    companyData: function () {
      this.prepareData();
      this.checkIfPageIsDisabled();
    },
    activeEditionOpened() {
      this.checkIfPageIsDisabled();
    },
    "formData._eb_logo_option": function () {
      if (this.formData._eb_logo_option === "logo-selected-places") {
        this.ignoredKeys["first-booth-option"].push("_ed_logo");
      } else {
        const index = this.ignoredKeys["first-booth-option"].indexOf("ed_logo");
        if (index !== -1) {
          this.ignoredKeys["first-booth-option"].splice(index, 1);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.checkboxs-container {
  display: flex;
  flex-wrap: wrap;
  margin: 30px;

  .inpt-group {
    width: 33%;
    margin-bottom: 20px;
  }
}
</style>
